<template>
   <ul class="pagination m-0 ms-5">
      <li class="page-item" :class="{ disabled: disabled || inputValue === 1 }">
         <a @click.prevent="prevPage" class="page-link"> <IconPrev /> </a>
      </li>
      <li class="page-item" :class="{ disabled: disabled || inputValue === get(data, 'meta.last_page') }">
         <a @click.prevent="nextPage" class="page-link"> <IconNext /> </a>
      </li>
   </ul>
</template>

<script>
import get from 'lodash/get';

import IconNext from '../icons/IconNext';
import IconPrev from '../icons/IconPrev';

export default {
   name: 'AmcPagination',
   components: {
      IconNext,
      IconPrev,
   },
   props: {
      value: {},
      disabled: {
         type: Boolean,
         default: false,
      },
      data: {
         type: [Object, Array],
         default: () => {},
      },
   },
   computed: {
      inputValue: {
         get() {
            return parseInt(this.value, 10);
         },
         set(val) {
            if (val === this.inputValue) return;

            this.$emit('input', val);
         },
      },
   },
   methods: {
      get,
      prevPage() {
         let value = this.inputValue - 1;
         if (value < 1) {
            value = 1;
         }
         this.inputValue = value;
      },
      nextPage() {
         let value = this.inputValue + 1;
         if (value >= get(this.data, 'meta.last_page')) {
            value = get(this.data, 'meta.last_page');
         }
         this.inputValue = value;
      },
   },
};
</script>

<style lang="scss" scoped>
.pagination {
   user-select: none;

   .page-item {
      .page-link {
         cursor: pointer;
         border: none;
         margin-right: 20px;
         padding-top: 0;
         &:hover {
            background-color: transparent;
         }
      }
   }
}
</style>
